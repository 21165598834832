//General Imports
import React from "react";
import { IntlProvider } from "react-intl";
import { graphql, Script } from "gatsby";
import messages from "../../i18n-translations.json";

//Layout
import PostsList from "../components/posts/PostsList";
import Layout from "../components/Layout";
import HeaderScripts from "../components/HeadScripts";

export function Head() {
  return (
    <>
      <HeaderScripts />
      <Script
        async
        src="https://boot.pbstck.com/v1/tag/8c704138-0cf5-43be-9bf5-416e674a06d9"
        type="text/javascript"
      />
    </>
  );
}

const LangIndex = (props) => {
  const { allWpCategory } = props.data;
  const { pageContext } = props;

  const categories = allWpCategory.nodes.filter((category) => {
    if (
      category.slug.startsWith("non-classe") ||
      category.slug.startsWith("uncategorized")
    ) {
      return null;
    }
    return category;
  });

  return (
    <IntlProvider
      locale={pageContext.locale}
      messages={messages[pageContext.locale]}
    >
      <Layout props={props}>
        <div className="indexpage">
          <section className="indexpage__post-categories">
            {categories.map((category, index) => (
              <div className="indexpage__post-list">
                <PostsList
                  posts={category.posts.nodes}
                  category={category.name}
                  categorySlug={category.slug}
                  limit={8}
                  modifier="indexpage"
                />
              </div>
            ))}
          </section>
        </div>
      </Layout>
    </IntlProvider>
  );
};

export default LangIndex;

export const query = graphql`
  query ($defaultCategorySlugs: [String], $slug: String!) {
    allWpCategory(
      filter: {
        slug: { nin: $defaultCategorySlugs }
        language: { slug: { eq: $slug } }
        count: { gte: 4 }
      }
      sort: { fields: name, order: ASC }
      limit: 4
    ) {
      nodes {
        name
        slug
        posts {
          nodes {
            id
            slug
            title
            excerpt
            originalSlug
            date
            author {
              node {
                name
              }
            }
            featuredImage {
              node {
                title
                mediaItemUrl
                mediaDetails {
                  filteredSizes {
                    name
                    sourceUrl
                  }
                  sizes {
                    name
                  }
                }
              }
            }
            categories {
              nodes {
                name
                slug
              }
            }
            seo {
              title
              metaDesc
              focuskw
              metaKeywords
              opengraphTitle
              opengraphDescription
              opengraphImage {
                altText
                sourceUrl
                srcSet
              }
              twitterTitle
              twitterDescription
              twitterImage {
                altText
                sourceUrl
                srcSet
              }
              canonical
              cornerstone
              schema {
                articleType
                pageType
                raw
              }
            }
          }
        }
        language {
          slug
          name
        }
      }
    }
  }
`;
