import React from "react";
import ChevronRightIcon from "../../images/svg/icons/chevron-right-icon.svg";

import { Link } from "gatsby";
import { useIntl } from "react-intl";
import { defaultLanguage } from "../../i18n";

import PostItem from "./PostItem";

const PostsList = ({ posts, category, categorySlug, limit, modifier }) => {
  let recentPosts = limit ? posts.slice(0, limit) : posts;
  const intl = useIntl();
  const locale =
    defaultLanguage === intl.locale
      ? `${process.env.GATSBY_DEFAULT_LANG}`
      : `${intl.locale}`;
  let showAllSlug =
    process.env.GATSBY_MULTILINGUAL === "true" ? `/${locale}/` : `/`;
  if (categorySlug) {
    showAllSlug =
      process.env.GATSBY_MULTILINGUAL === "true"
        ? `/${locale}/category/${categorySlug}`
        : `/category/${categorySlug}/`;
  }

  const postListClass = modifier
    ? `posts-list posts-list--${modifier}`
    : "posts-list";

  return (
    <div className={postListClass}>
      {category && (
        <div className="posts-list__category-name">
          <h2 className="posts-list__title">{category}</h2>
          <div className="posts-list__seperator"></div>
          <div className="posts-list__link">
            <Link to={showAllSlug}>
              <span className="posts-list__custom-link-text">Show all</span>
              <span className="posts-list__custom-link-icon">
                <ChevronRightIcon />
              </span>
            </Link>
          </div>
        </div>
      )}
      <ul className="posts-list__list">
        {recentPosts.map((post) => (
          <li key={post.id} className="posts-list__list-item">
            <PostItem postContent={post} category={category} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PostsList;
